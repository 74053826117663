/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Otázky a odpovědi"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu pb--06 pt--20" anim={null} menu={true} animS={null} border={null}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box" style={{"maxWidth":""}}>
                
                <Subtitle className="subtitle-box fs--20 w--600 swpf--uppercase lh--14 pl--0 pr--0" style={{"maxWidth":""}} content={"Čištění DPF Ostrava"}>
                </Subtitle>

                <Subtitle className="subtitle-box fs--18 subtitle-box--invert lh--1 mt--0 pl--0 pr--0" content={"<span style=\"color: rgb(213, 164, 106);\">228 227 043</span>"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"#cenik"} target={""} content={"Otázky a odpovědi"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/kontakt"} target={""} content={"Kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn1 btn-box--pbtn3 btn-box--shape3 fs--18 w--500 swpf--uppercase lh--1 pl--08 pr--08" innerClassName="pb--10 pt--10" href={"/objednavka"} target={""} content={"Vyčistit filtr"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--0 pt--0" name={"sluzby-1"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}}>
        </Column>


        <Column className="--center pb--60 pt--80" name={"uwhjufg46ks"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--40 pr--40 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left pb--10 pt--50" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Otázky &amp; odpovědi</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--16" name={"uwhjufg46ks"}>
          
          <ColumnWrap className="column__flex --shape3 --border3 --left el--2 pb--30 pl--40 pr--40 pt--30 flex--top" anim={null} animS={null} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"<span style=\"color: rgb(44, 39, 63);\">Jak objednat čištění filtru, a co doprava?</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Stačí vyplnit buď náš rezervační formulář, nebo zavolat na naše kontaktní telefonní číslo. Při elektronické objednávce se Vám vždy zpětně telefonicky ozveme a telefonicky si potvrdíme objednávku\nDopravu filtru objednáme za Vás včetně vyzvednutí na Vámi určeném místě. Po vyčištění filtr zašleme obratem zpět.\nVšechny kroky od zaslání objednávky jsou již na nás.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={null} animS={null} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"<span style=\"color: rgb(44, 39, 63);\">Jde vyčistit každý filtr?</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Každý filtr opravdu vyčistit nedokážeme, proto před každým čištěním provádíme důkladnou diagnostiku filtru a hledáme možná poškození filtru. Pokud filtr kontrolou projde, je následně vyčištěn. V případě, že filtr vyčistit nelze (poškozené jádro, zapečení, netěsnost filtru apod.) Vás okamžitě kontaktujeme a hledáme alternativní řešení.\nPokud filtr vyčistit nelze, neplatíte nám ani korunu. Snažíme se být férovou firmou.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"Co když nelze filtr vyčistit?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Jsme schopni Vám nabídnout filtr nový, případně samotnou keramiku filtru. Tyto případy řešíme vždy individuálně na základě typu poškozeného filtru.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"Jak dlouho trvá samotné čištění?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Samotné čištění trvá zhruba dvě hodiny (běžné osobní vozidlo). Filtr Vám tedy můžeme vyčistit na počkání. Při využití přepravců dokážeme filtr odeslat ve stejný den, kdy je nám filtr na čištění doručen. Pokud nemáte času nazbyt a chcete vyřídit vše během JEDNOHO dne včetně dopravy, zašleme vlastní svozový vůz přímo k Vám a ještě tentýž den filtr vrátíme.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"Jak je to se zárukou?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Za kvalitou naší práce si stojíme a poskytujeme za ni plnou záruku. Filtr je na základě TUV certifikované čistící linky průchozí vždy na 98%, vše je také potvrzeno protokolem o čištění. Je třeba však upozornit, že v případě znovuzanesení filtru není důvodem špatné vyčištění, ale závada na vozidle. Při prvotním zanesení DPF je třeba vždy jasně určit proč k zanesení došlo a závadu na vozidle vyřešit, to už Vám poradí naši servisní partneři, případně přímo náš specialista na komplexní řešení závad na systémech DPF.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"Čistíte i katalyzátory?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Ano, většinou je katalyzátor v jednom obalu spolu s DPF a tím pádem k vyčištění dojde vždy. Pokud je katalyzátor samostatně, vyčistíme jej také. Váš opravce by měl vždy také ověřit, zda-li katalyzátor funguje správně. Jeho nesprávná funkce může zapříčinit zanesení DPF filtru.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"<span style=\"color: rgb(44, 39, 63);\">Jste schopni opravu dodat komplexně, včetně demontáže z auta?\n</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Samotné čištění trvá zhruba dvě hodiny (běžné osobní vozidlo). Filtr Vám tedy můžeme vyčistit na počkání. Při využití přepravců dokážeme filtr odeslat ve stejný den, kdy je nám filtr na čištění doručen. Pokud nemáte času nazbyt a chcete vyřídit vše během JEDNOHO dne včetně dopravy, zašleme vlastní svozový vůz přímo k Vám a ještě tentýž den filtr vrátíme.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"Existuje nějaké potvrzení o kvalitě čištění filtru?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Ano, každý filtr je před čištěním podroben diagnostice, následně jsou na filtru za pomocí čistící linky změřeny hodnoty protitlaku před čištěním a následně po vyčištění filtru. Všechny tyto hodnoty máme pro každý filtr zdokumentovány a vy následně obdržíte PROTOKOL O VYČIŠTĚNÍ FILTRU a veškerou námi pořízenou dokumentaci (jak fotodokumentaci, tak videodokumentaci z čištění)&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 mt--20 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":390}} content={"Je čištění tzn. proplachem účinné a bezpečné?<br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Občas se v praxi setkáváme s názorem, že jedinou vhodnou metodou je filtr rozřezat a následně čistit. Námi provozovaná linka prošla certifikací TUV Nord, kdy byla potvrzena 98% účinnost čištění. Linka tak má veškerou certifikaci a jedná se PATENTOVANÉ zařízení umožňující efektivní čištění filtru bez rizika poškození filtru při řezání.\nKaždý filtr je po vyčištění dokonale vysušen v čistící lince a otestován.\nTato technologie je v západní Evropě léty ověřená a provozována (DE, GB, IT apod.), z každého čištění je pořízen protokol a kvalitu čištění potvrzuje i již zmiňovaná certifikace TUV a množství spokojených zákazníků, kteří využili našich služeb. Oproti jiným technologiím je právě toto čištění pro filtr vhodnější, nedochází k řezání filtru a riziku mechanického poškození jádra při manipulaci.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--0" name={"uwhjufg46ks"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pl--40 pr--40 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left pb--10 pt--50" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--36 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Nenašli ste na svou otázku odpověď?<br>Ozvěte se nám.</span>"}>
              </Title>

              <Button className="btn-box btn-box--shape3 btn-box--center fs--20" content={"228 227 043"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-wwg8dr --parallax pb--80 pt--80" name={"p0zcyis97o"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12956/8092bd71598b4ba2972c9367adf09381_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--40 pt--40" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":910}} content={"Poptejte si čištění DPF filtru ješte dnes."}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3" href={"/objednavka"} content={"Poptat nyní"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80">
          
          <ColumnWrap className="column__flex --left el--4 pl--40 pr--40 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--48 w--600 swpf--uppercase ls--002 lh--12 mt--0 pl--0" content={"<span style=\"color: rgb(44, 39, 63);\">Čištění DPF</span>"}>
              </Title>

              <Subtitle className="subtitle-box fs--20 ls--002 mt--02 pl--0" content={"<span style=\"color: rgb(213, 164, 106);\">Komplexní služby Ostrava</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--18 w--600 lh--12 mt--0 pl--0" content={"<span style=\"color: rgb(213, 164, 106);\">Služba</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--25 pl--0 pr--0" href={"#cenik"} content={"Ceník čištění DPF"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--16 pl--0 pr--0" href={"#jak"} content={"Jak to funguje"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--16 pl--0 pr--0" href={"/otazky-a-odpovedi"} content={"Otázky a odpovědi"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--16 pl--0 pr--0" href={"/objednavka"} content={"Poptat čištění"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--18 w--600 lh--12 mt--0 pl--0" content={"<span style=\"color: rgb(213, 164, 106);\">Kontakt</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--25 pl--0 pr--0" content={"228 227 043"}>
              </Button>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 ls--002 mt--06 pl--0 pr--0" content={"info@cisteni-dpf-ostrava.cz"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn1 btn-box--pbtn4 btn-box--shape3 btn-box--filling2 fs--18 ls--002 mt--25 pl--0 pr--0" href={"/objednavka"} content={"Poptat čištění"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}